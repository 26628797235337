.animation--scroll{
    animation-name: scroll;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes scroll {
    0% { 
        opacity: 0;
        transform: translateY(-12px);
    }
    15% { 
        opacity: 1;
        transform: translateY(0px);
    }
    70% { 
        opacity: 1;
        transform: translateY(0px);
    }
    95%{
        opacity: 0;
        transform: translateY(16px);
    }
    100%{
        opacity: 0;
        transform: translateY(16px);
    }
}