@import 'bootstrap/scss/bootstrap';
@import "./assets/scss/reset.scss";
@import "./assets/scss/variables.scss";
@import "./assets/scss/animations.scss";
@import "./assets/scss/icons.scss";

body * {
    font-family: 'mortise', serif;
    font-weight: 400;
    color: #1A1A1A;
    font-size: 16px;
    box-sizing: border-box;
}
.container{
    width: 100%;
    display: block;
    margin: auto;
    max-width: calc(1280px + 16px + 16px);
    padding: 0 16px;
}

.section-sep{
    height: 20px;
    width: 100%;
    background-image: url('/assets/img/misc/sep-pattern.svg');
    background-repeat: repeat;
    background-position: top left;
    background-size: 40px 20px;
}
.link{
    color: #1A428A;
    text-decoration: underline;
    &:hover{
        color: #1A428A;
    }
    &.link--external{
        display: inline-block;
        i{
            margin-left: 4px;
        }
    }
}
.fontweight-300{
    font-weight: 300;                           
}
.fontweight-400{
    font-weight: 400;
}
.fontweight-500{
    font-weight: 500;
}

// modals
.modal-backdrop.show {
    opacity: 0.4;
}
.close-modal-wrap{
    padding: 16px;
    margin: -16px;
    .icon-close-modal{
        cursor: pointer;
    }
}
.modal{
    .close-modal-wrap{
        position: absolute;
        top: 32px;
        right: 32px;
    }
}
.modal--1280{
    .modal-dialog{
        width: 1280px;
        max-width: calc(100vw - 48px);
        margin-top: 32px !important;
        margin-bottom: 32px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        .modal-content{
            border-radius: 4px;
            box-shadow: 0px 6px 16px 0px #0000001F;
            overflow: hidden;
            border: none;
        }
    }
}
@media only screen and (max-width: 500px) {
    .modal--1280{
        .modal-dialog{
            max-width: calc(100vw);
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
            .modal-content{
                border-radius: 0;
            }
        }
    }
}


// injected html content
.webshop-popup .html-wrap{
    font-size: 18px;
    line-height: 26px;
    font-family: 'Inter', sans-serif;
    *{
        font-size: 18px;
        line-height: 26px;
        font-family: 'Inter', sans-serif;
    }
    p{
        margin-bottom: 14px;
    }
}
.webshop-popup .li-html-wrap{
    font-size: 18px;
    line-height: 26px;
    font-family: 'Inter', sans-serif;
    *{
        font-size: 18px;
        line-height: 26px;
        font-family: 'Inter', sans-serif;
    }
}
@media only screen and (max-width: 1000px) {
    .webshop-popup .html-wrap{
        font-size: 14px;
        line-height: 20px;
        *{
            font-size: 14px;
            line-height: 20px;
        }
        p{
            margin-bottom: 14px;
        }
    }
    .webshop-popup .li-html-wrap{
        font-size: 14px;
        line-height: 20px;
        *{
            font-size: 14px;
            line-height: 20px;
        }
    }
}