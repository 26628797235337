.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.icon-phone-red{
        background-image: url(/assets/img/icons/phone-red.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-scroll{
        background-image: url(/assets/img/icons/scroll.svg);
        width: 20px;
        height: 42px;
    }
    &.icon-close-modal{
        background-image: url(/assets/img/icons/close-modal.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-bank-transfer{
        background-image: url(/assets/img/icons/bank-transfer.svg);
        width: 48px;
        height: 48px;
    }
    &.icon-paypal{
        background-image: url(/assets/img/icons/paypal.svg);
        width: 48px;
        height: 48px;
    }
    &.icon-external{
        background-image: url(/assets/img/icons/external.svg);
        width: 12px;
        height: 12px;
    }
    &.icon-webshop-cart{
        background-image: url(/assets/img/icons/webshop-cart.svg);
        width: 64px;
        height: 64px;
    }
    &.icon-chev-right-white{
        background-image: url(/assets/img/icons/chev-right-white.svg);
        width: 8px;
        height: 8px;
    }
}